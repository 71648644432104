export default function(props){
		if (
			props.session.data.preTest ||
			props.session.data.introductionText ||
			props.session.data.introduction
		) {
			return 'Next'
		} else {
			return 'Start Test'
		}
}
