import React from "react";
import {connect} from "react-redux";
import {allUserData} from "../../../actions/allUsers";
import {useNavigate} from "react-router-dom";
import startTest from "../../../util/usertesting/startTest";
/**
 * Description: Wrapper to display props.children  as a Modal
 * @param {*} header - Modal title
 * @param {*} handleCloseModal - Cancel method for button
 * @param {*} handleSubmit - react-use-form submit call back
 * @param {*} searchSelectName - Name of react-select component
 * @param {*} message - Instruction to display to the user
 * @param {Optional} modalClass - Place modal class name on component
 * @param {Optional} primaryText - Button label text, defaults to save in not passed
 * @returns
 */
const Modal = (props) => {
	const navigate = useNavigate();
	function handleSubmit() {
		localStorage.setItem('userTester', JSON.stringify(props.session));
		window.location.href = `/apps/${props.session.data.repoName}`
	}
  return (
    <div className="main-content pt-0">
      <div className="container-fluid">
          <div className={props.modalClass? `modal d-block ${props.modalClass}`: "modal d-block"} style={{background: 'rgb(0 0 0 / 82%)'}}>
            <div className="modal-dialog" role="dialog">
              <div className="modal-content modal-content-demo shadow pd-t-50">
                <div
                  className="detail-modal-body"
                  style={{ paddingLeft: "50px", paddingRight: "50px" }}
                >
                  <div className="form-group">
                    {props.message?<p>{props.message}</p>:<></>}
                    {props.children}
                  </div>
                </div>

                <div className="modal-footer">
                  <button  onClick={handleSubmit} className="btn ripple btn-primary" type="submit">
                    {props.primaryText ? props.primaryText : 'Save'}
                  </button>
                  <button
                    className="btn ripple btn-secondary muted"
                    type="button"
                    onClick={()=>{navigate('/sign-out')}}
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session, users }) => ({
  session,
  users,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
