import React from 'react';
import Modal from "./global/templates/Modal";
import Wrapper from "./global/Wrapper";

const Confirmation = (props) => {

	return (
		<Wrapper>
		<Modal
			data={props}
			primaryText={'Start Test'}
		>
			<h1 style={{color: 'black'}}>Recording Confirmation</h1>
			<h5 style={{color: 'black', lineHeight: '1.5'}} >The tester has requested to record your webcam session during this test. Please read the following information before proceeding:</h5>
			<ol style={{marginTop:'15px'}}>
				<li>The recorded session will only be accessible to the tester and authorized personnel.</li>
				<li>The purpose of recording is to gather valuable insights for improving our services.</li>
				<li>Only the tester's webcam video will be recorded; no other personal information or sensitive data will be captured.</li>
			</ol>
			<p><strong>By clicking the "Proceed" button below, you acknowledge and agree to the following:</strong></p>
			<ul>
				<li>I understand that my webcam session will be recorded.</li>
				<li>I confirm that I am the tester and authorized to provide consent for recording.</li>
				<li>I acknowledge that the recording will be used solely for testing and improving purposes.</li>
				<li>I understand that the recorded session will not be shared with any third parties.</li>
			</ul>
		</Modal>
		</Wrapper>
	);
};

export default Confirmation;
