import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import React, {useEffect, useRef, useState} from "react";
import Avatar from "./Avatar";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {signout} from "../../actions/session/signOut";
import {myNotifications} from "../../actions/myNotifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import gitlabAvatar from "../../assets/images/gitlab-avatar.png";
import digitalAvatar from "../../assets/images/digitalocean.png";
import {closeWebSocket} from "../../util/websockets/closeWebSocket";
import {saveWSClient} from '../../actions/wsclient'
import logo from '../../assets/images/framework/storybook.png'
// MSAL Logout
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import Toast from "./Toast";

const Header = (props) => {
  // MSAL: Determine is user is currently signed in
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
	const [showToastMessage, setShowToast] = useState(null)
  useEffect(() => {
    fetch(`${AUTH_BASE_URL}notifications?id=${props.session.userId}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((notifications) => {
        props.myNotifications(notifications.data);
      })
      .then((final) => {
        if (props.notifications) {
        }
      });
  }, [isAuthenticated]);

  const navigate = useNavigate();
  const notificationsRef = useRef(null);
  const messagesRef = useRef(null);
  const profileRef = useRef(null);
  // const teamsUrl =
  //   "https://teams.microsoft.com/l/team/19%3aYSuc4aIpjlHcsZ9QjWSiyGwpZi7c6B8QHkDQYv2pdTY1%40thread.tacv2/conversations?groupId=6b413c92-d5b0-43c3-b6ac-70f19b285fb0&tenantId=8cc434d7-97d0-47d3-b5c5-14fe0e33e34b";

  const handleNotificationsMenu = () => {
    if (notificationsRef.current.classList.contains("show")) {
      notificationsRef.current.classList.remove("show");
    } else {
      notificationsRef.current.classList.add("show");
      if (profileRef.current.classList.contains("show")) {
        profileRef.current.classList.remove("show");
      }
    }
  };

  const handleProfileMenu = () => {
    if (profileRef.current.classList.contains("show")) {
      profileRef.current.classList.remove("show");
    } else {
      profileRef.current.classList.add("show");
      if (notificationsRef.current.classList.contains("show")) {
        notificationsRef.current.classList.remove("show");
      }
    }
  };
  function removeNotification(event, id) {

	  // if (event.currentTarget === '')
	  if (event.target.tagName !== 'BUTTON') {
		  // console.log('notification id', id)
		  fetch(`${AUTH_BASE_URL}notifications`, {
			  method: "DELETE",
			  body: JSON.stringify({ data: id }),
			  headers: {
				  "Content-Type": "application/json",
			  },
		  }).then((response) => {
			  fetch(`${AUTH_BASE_URL}notifications?id=${props.session.userId}`, {
				  method: "GET",
			  })
				  .then((response) => response.json())
				  .then((notifications) => {
					  // console.log('my notificaitons', notifications)
					  props.myNotifications(notifications.data);
				  });
		  });
	  }

  }
	const handleCloseToast = () => {
		setShowToast(null)
	}
  function avatarSwitcher(from) {
    if (from === "gitlab") {
      return gitlabAvatar;
    } else if (from === "digital") {
      return digitalAvatar
    }
  }
  function handleRequestHelp(event, data) {
	  event.target.disabled = true
	  fetch(`${AUTH_BASE_URL}dev-ops/help`, {
		  method: 'POST',
		  body: JSON.stringify(data.data),
		  headers: new Headers({
			  'Content-Type': 'application/json'
		  })
	  }).then(response => {
		  setShowToast('Help is on the way.  You will receive an email / notification when the issue is fixed.')
		  setTimeout(() => {
			  setShowToast(null)
		  }, 10000)
	  })
  }
 async function handleSignout(event) {
    event.preventDefault();
    // console.log("Header: In handleSignout")
    // MSAL Logout
    if (isAuthenticated) {
    // The account's ID Token must contain the login_hint optional claim to avoid the account picker
    // console.log("In Logout Redirect: account:", accounts[0]);
    instance.logoutPopup({ account: accounts[0]});
    }
    props.signout();
    closeWebSocket().then(response=>response.json()).then(()=>{
      props.wsClient.close()
      props.saveWSClient(null)
    })

  }

  return (
	  <>
		  {
			  showToastMessage != null ? <Toast closeToast={handleCloseToast} message={showToastMessage}/>
				  : null
		  }
		  <div
			  className={
				  props.noSidebar
					  ? "main-header sticky sticky-pin"
					  : "main-header side-header sticky sticky-pin"
			  }
		  >

			  <div className="container-fluid">
				  <div className="main-header-left">
					  <div className={'framework-banner d-flex align-items-center justify-items-center'}>
						  <img src={logo} style={{width:'30px'}} />
						  <h4 className={'mg-0 mg-l-8 mg-r-8' }>Storybook / React codebase is now available.</h4>
						  <button id="readMore" onClick={()=>{window.open("https://storybook.js.org/","_blank")}} type="button" className="btn btn-sm btn-secondary mg-0 mg-r-8">Read more</button>
						  <button id="tryNow" onClick={()=>{navigate('/dev-ops/gitlab-repos/add')}} type="button" className="btn btn-sm btn-primary mg-0">Try it out</button>
					  </div>

					  <button
						  className="icon main-header-menu-btn"
						  onClick={props.handleSidebar}
					  >
						  <FontAwesomeIcon icon="fa-solid fa-bars" />
					  </button>

				  </div>
				  <div className="main-header-right">

					  <div
						  ref={notificationsRef}
						  className="dropdown main-header-notification"
					  >

						  <button onClick={handleNotificationsMenu} className="nav-link icon">
							  <FontAwesomeIcon icon="fa-solid fa-bell" />
							  {props.notifications && props.notifications.length !== 0 ? (
								  <span className="badge bg-danger nav-link-badge">
                  {props.notifications.length}
                </span>
							  ) : (
								  <></>
							  )}
						  </button>
						  <div className="dropdown-menu">

							  <div className="header-navheading">
								  <p className="main-notification-text">
									  You have{" "}
									  {props.notifications ? props.notifications.length : ""} unread
									  notifications
								  </p>
							  </div>
							  <div className="main-notification-list">
								  {props.notifications ? (
									  props.notifications.map((item, i) => {
										  if (item.action) {
											  return (
												  <div
													  key={i}
													  className="media new"
													  onClick={(event) => {
														  removeNotification(event, item._id);
													  }}
													  style={{ textAlign: "left" }}
												  >
													  <div className="main-img-user online">
														  <img alt="avatar" src={avatarSwitcher(item.from)} />{" "}
													  </div>
													  <div className="media-body">
														  <p>{item.message}</p><button onClick={(event)=> {handleRequestHelp(event, item.data)}} className={'btn btn-sm btn-primary btn-icon-text mg-b-10'}>Request Help!</button>
														  <span>
                            {new Date(item.createdAt).toLocaleDateString() +
								" " +
								new Date(item.createdAt).toLocaleTimeString()}
                          </span>
													  </div>

												  </div>
											  );

										  } else {
											  return (
												  <button
													  key={i}
													  className="media new"
													  onClick={(event) => {
														  removeNotification(event, item._id);
													  }}
													  style={{ textAlign: "left" }}
												  >
													  <div className="main-img-user online">
														  <img alt="avatar" src={avatarSwitcher(item.from)} />{" "}
													  </div>
													  <div className="media-body">
														  <p>{item.message}</p>
														  <span>
                            {new Date(item.createdAt).toLocaleDateString() +
								" " +
								new Date(item.createdAt).toLocaleTimeString()}
                          </span>
													  </div>
												  </button>
											  );

										  }
									  })
								  ) : (
									  <></>
								  )}

								  {/*<div className="media">*/}
								  {/*    <div className="main-img-user"><img alt="avatar" src="../../assets/img/users/2.jpg" /></div>*/}
								  {/*    <div className="media-body">*/}
								  {/*        <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>*/}
								  {/*    </div>*/}
								  {/*</div>*/}
								  {/*<div className="media">*/}
								  {/*    <div className="main-img-user online"><img alt="avatar" src="../../assets/img/users/3.jpg" /></div>*/}
								  {/*    <div className="media-body">*/}
								  {/*        <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>*/}
								  {/*    </div>*/}
								  {/*</div>*/}
							  </div>
						  </div>
					  </div>
					  {/*<div className="main-header-notification">*/}
					  {/*  <a className="nav-link icon" href="#">*/}
					  {/*    <FontAwesomeIcon icon="fa-solid fa-message" />*/}
					  {/*    <span className="badge bg-success nav-link-badge">6</span>*/}
					  {/*  </a>*/}
					  {/*</div>*/}
					  <div className="dropdown main-profile-menu">
						  <button onClick={handleProfileMenu} className="d-flex">
              <span className="main-img-user">
                <Avatar user={props.session} size="sm" />
              </span>
						  </button>
						  <div
							  ref={profileRef}
							  className="dropdown-menu"
							  style={{ width: "200px" }}
						  >
							  <div className="header-navheading">
								  <h6 className="main-notification-title">
									  {props.session.firstName} {props.session.lastName}
								  </h6>
								  <p className="main-notification-text">{props.session.role}</p>
							  </div>
							  <button
								  className="dropdown-item"
								  onClick={() => {
									  navigate("/settings");
								  }}
							  >
								  <i className="fe fe-settings" />
								  Settings
							  </button>
							  <button
								  className="dropdown-item"
								  // onClick={() => {
								  //   window.open(teamsUrl, "_blank");
								  // }}
							  >
								  <i className="fe fe-settings" /> Support
							  </button>
							  <button className="dropdown-item" onClick={handleSignout}>
								  <i className="fe fe-power" /> Sign Out
							  </button>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </>

  );
};

const mapStateToProps = ({ session, notifications, wsClient }) => ({
  session,
  notifications,
  wsClient
});

const mapDispatchToProps = (dispatch) => ({
  signout: () => dispatch(signout()),
  myNotifications: (data) => dispatch(myNotifications(data)),
  saveWSClient: (data) => dispatch(saveWSClient(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
