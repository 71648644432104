import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Wrapper from './global/Wrapper'
import {allUserData} from "../actions/allUsers";
import {useNavigate} from "react-router-dom";
import {userTest} from "../actions/userTest";
import sendTestData from "../util/usertesting/sendTestData";
import startTest from "../util/usertesting/startTest";
import buttonText from "../util/buttonText"

const Information = props => {
	const navigate = useNavigate();
	const [data, setData] = useState();

	useEffect(() => {
		setData(Object.assign({}, props.userTestData))
	},[props.userTestData === null])


	function handleInformation () {

		function capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}

		function returnLabel (current) {
			if (current === 'firstname') {
				return 'First Name'
			} else if (current === 'lastName') {
				return 'Last Name'
			} else {
				return capitalizeFirstLetter(current)
			}
		}


		let arr = []
		for (const [key, value] of Object.entries(props.session.data.personalData[0])) {
			if (value && key !== 'name') {
				arr.push(key)
			}

		}
		return arr.map((item, index) => {

			if (props.userTestData && props.userTestData.data.data.information[index]) {

				let dataObj = props.userTestData.data.data.information
				let filtered = dataObj.filter(obj => {return obj.name.replace(/\s+/g, '') === item.toLowerCase()})[0]
				let name = item.toLowerCase().split('name').join(' ') + 'name'
				if (filtered) {
					return (
						<div key={index} className="form-group text-start">
							<label className="main-content-label tx-14 mb-1">{returnLabel(item)}</label>
							<input defaultValue={filtered.value} id={returnLabel(item).replace(/\s+/g, '')} className="form-control" type="text" required={false}  />
							<span className="error-text">Enter your {returnLabel(item)}</span>
						</div>
					)
				} else {
					return (
						<div key={index} className="form-group text-start">
							<label className="main-content-label tx-14 mb-1">{returnLabel(item)}</label>
							<input  id={returnLabel(item).replace(/\s+/g, '')} className="form-control" type="text" required={false}  />
							<span className="error-text">Enter your {returnLabel(item)}</span>
						</div>
					)
				}

			} else {
				return (
					<div key={index} className="form-group text-start">
						<label className="main-content-label tx-14 mb-1">{returnLabel(item)}</label>
						<input  id={returnLabel(item).replace(/\s+/g, '')} className="form-control" type="text" required={false}  />
						<span className="error-text">Enter your {returnLabel(item)}</span>
					</div>
				)
			}


		})
	}

	async function handleClick() {
		const FirstName = document.querySelector('#FirstName'),
			LastName = document.querySelector('#LastName'),
			Email = document.querySelector('#Email'),
			Phone = document.querySelector('#Phone'),
			Address = document.querySelector('#Address'),
			inputs = document.querySelectorAll('input');

		const info = {}
		let arr = []
		let dataArr = []
		if (data) {
			await inputs.forEach((input, index) => {
				let value = document.querySelector(`#${input.id}`).value
				if (value === '') {
					input.required = true
					arr.push(index)
				} else {
					info[input.id] = value
				}
			})

			if (arr.length !== 0) {
				return;
			}
			if (info.Address) {
				let data = {}
				data.name = 'address'
				data.value = info.Address
				dataArr.push(data)
			}
			if (info.FirstName) {
				let data = {}
				data.name = 'first name'
				data.value = info.FirstName
				dataArr.push(data)
			}
			if (info.LastName) {
				let data = {}
				data.name = 'last name'
				data.value = info.LastName
				dataArr.push(data)
			}
			if (info.Email) {
				let data = {}
				data.name = 'email'
				data.value = info.Email
				dataArr.push(data)
			}
			if (info.Phone) {
				let data = {}
				data.name = 'phone'
				data.value = info.Phone
				dataArr.push(data)
			}
			let finalData = Object.assign({}, data)
			finalData.data.data.information = [...dataArr]
			props.userTest(finalData.data.data)
			if (props.session.data.preTest) {
				await sendTestData(finalData.data.data, props.session.data._id, false)
				navigate("/questions")
			}  else if (props.session.data.introductionText) {
				await sendTestData(finalData.data.data, props.session.data._id, true)
				navigate("/welcome")
			}   else if (props.session.data.introduction) {
				await sendTestData(finalData.data.data, props.session.data._id, true)
				navigate("/video")
			}   else if (!props.session.data.preTest && !props.session.data.postTest) {
				await sendTestData(finalData.data.data, props.session.data._id, true)
				startTest(props)
			} else {
				await sendTestData(finalData.data.data, props.session.data._id, false)
				startTest(props)
			}
		}






	}


	return(
		<Wrapper>
			<h1>Your Information</h1>
			<div style={{width:'20%'}}>
				{handleInformation()}
			</div>
			<div style={{display: 'flex'}}>
				<button onClick={handleClick} className="btn ripple btn-primary"><span>{buttonText(props)}</span></button>
			</div>

		</Wrapper>
	)
}

const mapStateToProps = ({session, users, userTestData}) => ({
	session, users, userTestData
});

const mapDispatchToProps = dispatch => ({
	allUserData: (data) => dispatch(allUserData(data)),
	userTest: (data) => dispatch(userTest(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Information);
