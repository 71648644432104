import React, { useEffect, useRef, useState } from 'react';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';
import Header from './Header';


const Wrapper = (props) => {
	const [html, setHtml] = useState();

	useEffect(()=>{
		let theButton = document.querySelector("#endButton");
		if (theButton) {
			theButton.addEventListener("click", event => {window.location.href = '/sign-out'});
		}
	}, [html])

	useEffect(() => {
		fetch(`${AUTH_BASE_URL}header`, {
			method: 'GET',
			contentType: 'text/html',
		})
			.then((html) => {
				return html.text();
			})
			.then((data) => {
				setHtml(data);
			})

	});

	const wrapperRef = useRef(null);

	const handleSidebar = () => {
		if (wrapperRef.current.classList.contains('main-sidebar-show')) {
			wrapperRef.current.classList.remove('main-sidebar-show');
		} else {
			wrapperRef.current.classList.add('main-sidebar-show');
		}
	};

	return (
		<div ref={wrapperRef} className="page  leftmenu main-sidebar-show">
			{html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : null}
			<div className="main-content side-content user-test-system">
				<div className="container-fluid">{props.children}</div>
			</div>
		</div>
	);
};

export default Wrapper;
