export default function (props) {
	console.log('start test', props)
	localStorage.setItem('userTester', JSON.stringify(props.session));
	if (props.session.data.recordTest) {
		localStorage.setItem('userTester', JSON.stringify(props.session));
		window.location.href = `/confirmation`
	} else {
		localStorage.setItem('userTester', JSON.stringify(props.session));
		window.location.href = `/apps/${props.session.data.repoName}`
	}

}
