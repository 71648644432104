import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Wrapper from './global/Wrapper'
import {allUserData} from "../actions/allUsers";
import {useNavigate} from "react-router-dom";
import {userTest} from "../actions/userTest";
import sendTestData from "../util/usertesting/sendTestData";
import startTest from "../util/usertesting/startTest";


const Questions = props => {
	const navigate = useNavigate();

	useEffect(()=> {
		document.addEventListener('testSent', event => {
			setTimeout(function() {
				if (props.session.data.introductionText) {
					navigate('/welcome')
				} else if (props.session.data.introduction) {
					navigate('/video')
				} else {
					startTest(props)
				}
			},1000)

		})
	},[])

	function findValue (id) {
		if (props.userTestData) {
			let data =  props.userTestData.data.data.questions.filter(val => val.id === id)
			if (data && data.length > 0) {
				return data[0]
			}
		}
	}

	function yesNoValue (index) {
		if (findValue(`radio-${index}`)) {
			return true
		} else {
			return false
		}

	}

	function trueFalseValue (index) {
		if (findValue(`true-${index}`)) {
			return true
		} else {
			return false
		}

	}

	function selectValue (index) {
		if (findValue(`scale-${index}`)) {
			return findValue(`scale-${index}`).answer
		} else {
			return 0
		}
	}

	function answerValue (index) {
		if (findValue(`answer-${index}`)) {
			return findValue(`answer-${index}`).answer
		} else {
			return ''
		}
	}

	function handleInformation () {
		return props.session.data.preTestData.map((item, index) => {
			if (item.answer === 'Yes / No') {
				return (
					<div key={index} className="card-item" style={{marginBottom:'16px'}}>
						<div className="card-item-title mb-2">
							<label className="main-content-label tx-14 mb-1">{item.question}</label>
						</div>
						<div className="card-item-body">
							<div className="card-item-stat">
								<label htmlFor={`radio-${index}`} className="rdiobox">
									<input defaultChecked={yesNoValue(index)} type="radio" id={`radio-${index}`} name={`radio-${index}`} value="yes" />
										<span>Yes</span>
								</label>
							</div>
						</div>
						<div className="card-item-body">
							<div className="card-item-stat">
								<label htmlFor={`radio-${index+1}`} className="rdiobox">
									<input defaultChecked={yesNoValue(index+1)} type="radio" id={`radio-${index + 1}`} name={`radio-${index}`} value="no" />
										<span>No</span>
								</label>
							</div>
						</div>

					</div>				)
			} else if (item.answer === '1-10 Scale') {
				return (
					<div key={index} className="card-item form-group text-start" style={{marginBottom:'32px'}}>
						<label className="main-content-label tx-14 mb-1">{item.question} 1 - 10 Scale</label>
						<select defaultValue={selectValue(index)} id={`scale-${index}`} name="scale" className="form-control select" required="">
							<option data-index="0" value="0">0</option>
							<option data-index="1" value="1">1</option>
							<option data-index="2" value="2">2</option>
							<option data-index="3" value="3">3</option>
							<option data-index="4" value="4">4</option>
							<option data-index="5" value="5">5</option>
							<option data-index="6" value="6">6</option>
							<option data-index="7" value="7">7</option>
							<option data-index="8" value="8">8</option>
							<option data-index="8" value="9">9</option>
							<option data-index="8" value="10">10</option>
						</select>
						<span className="select2-selection__arrow" role="presentation">
   							<b role="presentation"></b>
  						</span>
					</div>
				)
			} else if (item.answer === 'True / False') {
				return (
					<div key={index} className="card-item" style={{marginBottom:'32px'}}>
						<div className="card-item-title  mb-2">
							<label className="main-content-label tx-14 mb-1">{item.question}</label>
						</div>
						<div className="card-item-body">
							<div className="card-item-stat">
								<label htmlFor={`true-${index}`} className="rdiobox">
									<input defaultChecked={trueFalseValue(index)} type="radio" id={`true-${index}`} name={`true-${index}`} value="true" />
									<span>True</span>
								</label>
							</div>
						</div>
						<div className="card-item-body">
							<div className="card-item-stat">
								<label htmlFor={`true-${index+1}`} className="rdiobox">
									<input defaultChecked={trueFalseValue(index+1)} type="radio" id={`true-${index + 1}`} name={`true-${index}`} value="false" />
									<span>False</span>
								</label>
							</div>
						</div>

					</div>
				)
			} else {
				return (
					<div key={index} className="card-item form-group text-start">
						<label className="main-content-label tx-14 mb-1">{item.question}</label>
						<textarea defaultValue={answerValue(index)} id={`answer-${index}`} className="form-control" type="text" required="" style={{minHeight: '130px'}}/>
						<span className="error-text">Enter your answer.</span>
					</div>
				)
			}

		})

	}

	async function handleClick() {
		const formElements = document.querySelectorAll('.card-item input:checked, .card-item select, textarea');
		let arr = []

		await formElements.forEach((item,index) => {
			if (item) {
				let preTestData = props.session.data.preTestData[index]
				let data = {}
				data.question = preTestData.question
				data.answer = item.value
				data.id = item.id
				arr.push(data)
			}
		})
		console.log(arr)
		props.userTestData.data.data.questions = arr
		let storeData = props.userTestData.data.data
		props.userTest(storeData)
		const event = new Event('testSent');
		if (props.session.data.postTest === false) {
			let newPromise = new Promise((resolve, reject) => {
				sendTestData(storeData, props.session.data._id, true).then(response => {
					resolve(response);
				})
			});
			Promise.resolve(newPromise).then(result => {
				document.dispatchEvent(event)
			})
		} else {
			let newPromise = new Promise((resolve, reject) => {
				sendTestData(storeData, props.session.data._id, false).then(response => {
					resolve(response);
				})
			});
			Promise.resolve(newPromise).then(result => {
				document.dispatchEvent(event)
			})

		}


	}

	async function handleBackClick() {
		const formElements = document.querySelectorAll('.card-item input:checked, .card-item select, textarea');
		let arr = []
		await formElements.forEach((item,index) => {
			if (item) {
				let preTestData = props.session.data.preTestData[index]
				let data = {}
				data.question = preTestData.question
				data.answer = item.value
				data.id = item.id
				arr.push(data)
			}
		})

		props.userTestData.data.data.questions = arr
		let storeData = props.userTestData.data.data
		props.userTest(storeData)

		if (props.session.data.collectPersonal) {
			navigate("/information")
		} else {
			navigate("/welcome")
		}

	}

	return(
		<Wrapper>
			<h1>Pre Test Questions</h1>

			<div style={{width:'20%'}}>
				{handleInformation()}
			</div>
			<div style={{display: 'flex'}}>
			{
				props.session.data.collectPersonal ? <button onClick={handleBackClick} className="btn ripple btn-primary mg-r-8"><span>Back</span></button> : <></>
			}
				<button onClick={handleClick} className="btn ripple btn-primary"><span>Next</span></button>

			</div>
		</Wrapper>
	)
}

const mapStateToProps = ({session, users, userTestData}) => ({
	session, users, userTestData
});

const mapDispatchToProps = dispatch => ({
	allUserData: (data) => dispatch(allUserData(data)),
	userTest: (data) => dispatch(userTest(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Questions);
