import AUTH_BASE_URL from "../AUTH_BASE_URL";
export default async function (data, id, status = null) {
	let newData ={}
	newData.data = data
	newData.id = id
	newData.finished = status
	newData.video = await JSON.parse(localStorage.getItem('video'))

	console.log(newData.video)
	fetch(`${AUTH_BASE_URL}usertesting/answers`, {
		method: 'PUT',
		body: JSON.stringify(newData),
		headers: {
			"Content-Type": "application/json",
		}
	}).then(response => {
		return response
	})
}
