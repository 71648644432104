import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import Wrapper from '../global/Wrapper'
import {updateSession} from "../../actions/session/updateSession";
// MSAL Logout
import {useIsAuthenticated, useMsal} from "@azure/msal-react";


const Dashboard = props => {

    // MSAL: Determine is user is currently signed in
    const isAuthenticated = useIsAuthenticated();
    const { instance, } = useMsal();
    const navigate = useNavigate();

    // Set Pagination Results
    const recommendedPageLimit = 10;
    const dataLimit = 20;

    const [filteredProjects, setFilteredProjects] = useState([])

    useEffect(()=> {
        let redirect = localStorage.getItem('redirect');
        if (props.session.firstTime) {
            navigate("/settings")
        } else {
            if (localStorage.getItem('redirect')) {
                localStorage.removeItem('redirect');
                window.location.href = redirect
            } else {
                navigate("/dashboard")
            }

        }
    }, []);



    return(
        // ALWAYS START THE PAGE WITH WRAPPER
        // THIS INCLUDES OUR SIDEBAR AND HEADER
        <Wrapper>

            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body dashboard">
                        <div className="page-header">
                            <div>
                                <h2>Hi {props.session.username}!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

const mapStateToProps = ({session, wsClient}) => ({
    session, wsClient
});

const mapDispatchToProps = dispatch => ({
    updateSession: (user) => dispatch(updateSession(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
