import {useEffect, useState} from "react";
import {useAccount, useMsal} from "@azure/msal-react";
import {callMsGraph} from "../../../util/sso/callMsGraph";
import {graphConfig} from "../../../util/sso/authConfig";
import {silentLogin} from "../../../util/sso/silentLogin";

/**
 * Description: Hook used to acquire a user AccessToken
 * and assembles the MSGraph request and receive
 * the response.  In the case of an account expiration
 * (id-token).  A new account is generated with  function
 * accountTokenWithOutAccount
 * @param {*} msGraphName - The name of the MS
 * Graph Config object in util/sso/authConfig
 * searchTerm: The term to search for in the
 * @param {*} searchTerm - The term to search for in the
 * MS Graph request.
 * @param {*} fields - The fields to return comma seperated
 * @returns
 */
export const useMsGraphFetch = (
  msGraphName,
  searchTerm = null,
  fields = null
) => {
  // Defaults
  const msConfigRoot = graphConfig[msGraphName];
  const endpoint = msConfigRoot.endpoint;
  const scopes = msConfigRoot.scopes;
  const type = msConfigRoot.type;

  // console.log(
  //   "UserFetchUser:Endpont:",
  //   msGraphName,
  //   "graphConfigRoot:",
  //   msConfigRoot,
  //   "; SearchTerm:",
  //   "*" + searchTerm + "*",
  //   "; Fields:",
  //   fields,
  //   "; Type:",
  //   type
  // );

  const [userData, setUserData] = useState(null);
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    // console.log("useMsGraphFetch: In useEffect:");
    // const noMsGraphAccount = !account;
    // Execute silent logon when native user  is accessing
    // the GraphAPI, not for SSO log in.
    if (!account  && msGraphName !== "graphMe") {
      // const runSilentLogin = async (scopes, instance) => await silentLogin(scopes, instance);
      silentLogin(scopes, instance).then(async (response) => {
        let newAccount = await response;
        // console.log("newAccount", newAccount);
        if (newAccount) {
          const idTokenClaims = newAccount.idTokenClaims;
          const forceRefresh = new Date(idTokenClaims?.exp + 1000) < new Date();
          acquireTokenWithOutAccount(
            instance,
            scopes,
            forceRefresh,
            type,
            searchTerm,
            endpoint,
            fields,
            setUserData,
            newAccount
          );
        }
      });
    } else if (account) {
      acquireTokenWithAccount(
        instance,
        scopes,
        account,
        msGraphName,
        type,
        searchTerm,
        endpoint,
        fields,
        setUserData
      );
    } //if
    // workaround for expired ID token
    // else if (newAccount)  //then
  }, [account, instance, searchTerm]);

  return [userData];
};
function acquireTokenWithAccount(
  instance,
  scopes,
  account,
  msGraphName,
  type,
  searchTerm,
  endpoint,
  fields,
  setUserData
) {
  instance
    .acquireTokenSilent({
      scopes,
      account: account,
      // forceRefresh: forceRefresh,
    })
    .then((response) => {
      if (response) {
        if (type === "people" && searchTerm) {
          // console.log("In Type:", type);
          const query = `${endpoint}?$search=${searchTerm}&select=${fields}`;
          callMsGraph(query, response.accessToken, instance).then((result) => {
            // console.log("useMsGraphFetch results:", result.value);
            setUserData(result.value);
          });
        } //if
        else if (type === "me" && fields === null) {
          callMsGraph(endpoint, response.accessToken, instance).then((result) =>
            setUserData(result)
          );
        }
      } // if
    }); // then
}

function acquireTokenWithOutAccount(
  instance,
  scopes,
  forceRefresh,
  type,
  searchTerm,
  endpoint,
  fields,
  setUserData,
  newAccount
) {
  // console.log("TokenWithOutAccount")
  instance
    .acquireTokenSilent({
      scopes,
      forceRefresh: forceRefresh,
      account: newAccount,
    })
    .then(
      (response) => {
        if (type === "people" && searchTerm) {
          // console.log("In Type:", type)
          const query = `${endpoint}?$search=${searchTerm}&select=${fields}`;
          callMsGraph(query, response.accessToken, instance).then((result) => {
            // console.log("useMsGraphFetch results:", result.value);
            setUserData(result.value);
          });
        } //if
        else if (type === "me" && fields === null) {
          callMsGraph(endpoint, response.accessToken, instance).then((result) =>
            setUserData(result)
          );
        }
      } // if
    ); // then
}
