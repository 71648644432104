import {loginRequest} from "./authConfig";

export async function callMsGraph(
    request,
    accessToken,
    msalInstance) {

    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    } // if

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(request, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
