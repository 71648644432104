import DropDown from "../dev-ops/gitlab-repos/DropDown";
import React from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function TableRow (props) {
	if (props.allTests && props.allTests.length === 0) {
		return (
			<tr>
				<td colSpan={8}>
					There are no tests setup
				</td>
			</tr>
		)
	} else {

		return (
			<tr>
				<td>
					<a href={'#'}>{props.data.url}</a>
				</td>
				<td>
					{props.data.repoName} - {props.data.branch}
				</td>

				<td>
					{props.data.users}
				</td>
				<td>
					{new Date(props.data.createdAt).toLocaleDateString()}
				</td>
				<td>
					<DropDown data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
						{/*<li><button  onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>View Test Results</button></li>*/}
						<li><button  onClick={()=>{props.handleEditModal(props.data)}} >Edit Test</button></li>
						{
							props.data.collectPersonal ? <li>{props.data.personalData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button  onClick={()=>{props.handleOpenCollectModal(props.data)}}> Collect User Data</button></li> : <></>
						}
						{
							props.data.preTest ? 	<li>{props.data.preTestData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button  onClick={()=>{props.handlePreQuestionModal(props.data)}}>Pre / Start Questions</button></li> : <></>
						}
						{
							props.data.postTest ? <li>{props.data.postTestData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button  onClick={()=> {props.handlePostQuestionModal(props.data)}}>Post / End Questions</button></li> : <></>
						}

						<li><button  disabled={true} className='disabled' onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Launch Test</button></li>
						{/*<li><button  onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Schedule Test</button></li>*/}

						{/*<li><button  className='text-danger' onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Delete Test</button></li>*/}
					</DropDown>
				</td>
			</tr>
		)
	}

}
const mapStateToProps = ({allTests}) => ({
	allTests
});


export default connect(
	mapStateToProps
)(TableRow);
