import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Wrapper from './global/Wrapper'
import {allUserData} from "../actions/allUsers";
import {useNavigate} from "react-router-dom";
import startTest from "../util/usertesting/startTest";

const Welcome = props => {
	const navigate = useNavigate();
	console.log(props.session.data)
	function handleClick() {
		startTest(props)
	}
	function handleBackClick() {
		if (props.session.data.collectPersonal) {
			navigate('/information')
		} else {
			navigate('/introduction')
		}
		startTest(props)
	}
	return(
		<Wrapper>
			<video width="640" height="480" controls>
				<source src={props.session.data.introductionData.video} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<div style={{display: 'flex', marginTop: '24px'}}>
				{
					props.session.data.collectPersonal || props.session.data.introduction ? <button onClick={handleBackClick} className="btn ripple btn-primary mg-r-8"><span>Back</span></button> : <></>
				}
				{
					props.session.data.introduction
						? <button onClick={() => navigate('/confirmation')} className="btn ripple btn-primary"><span>Next</span></button>
						: <button onClick={handleClick} className="btn ripple btn-primary"><span>Next</span></button>
				}
			</div>
		</Wrapper>
	)
}

const mapStateToProps = ({session, users}) => ({
	session, users
});

const mapDispatchToProps = dispatch => ({
	allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Welcome);
