import React, {useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Toast = props => {

    const toastRef = useRef(null)

    const handleClose = () => {
        toastRef.current.classList.add('fadeOut')
        setTimeout(()=>{
            props.closeToast()
        },300)
    }

    return(
        <div className="toast fadein" ref={toastRef}>
            <div>
                {props.type === 'success' ? <FontAwesomeIcon icon="fa-solid fa-circle-check" /> : null}
                {props.type === 'info' ? <FontAwesomeIcon icon="fa-solid fa-circle-info" /> : null}
                {props.type === 'alert' ? <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" /> : null}
                <p>{props.message}</p>
            </div>
            <button className="close" onClick={handleClose}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
            </button>
        </div>
    )
}


export default Toast
