import React, {useRef} from 'react';
import {connect} from "react-redux";
import updatePersonal from "../../util/usertesting/updatePersonal";
import allTests from "../../util/usertesting/allTests";
import {allTestData} from "../../actions/allUserTests";


const CollectUserData = props => {
	const firstRef = useRef()
	const lastRef = useRef()
	const emailRef = useRef()
	const phoneRef = useRef()
	const addressRef = useRef()
	function handleSave () {
		let data = {}
		data.firstname = firstRef.current.checked
		data.lastName = lastRef.current.checked
		data.email = emailRef.current.checked
		data.phone = phoneRef.current.checked
		data.address = addressRef.current.checked
		data.name = props.data.name

		updatePersonal(data).then(response => {
			props.handleCloseNewModal()
			allTests().then(data => {
				props.allTestData(data)
			})
		})
	}
	console.log('BLAH', props)
    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h6 className="modal-title">Collect User Data</h6>
                    </div>
					<form className={"pd-30"}>
						<div className={"mg-b-20"}>
						<label className="custom-switch"><input ref={firstRef} type="checkbox" name="custom-switch-checkbox"
																className="custom-switch-input" defaultChecked={props.data && props.data.personalData && props.data.personalData[0] ? props.data.personalData[0].firstname : false} /><span
							className="custom-switch-indicator"></span><span className="custom-switch-description">Collect first name of user?</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input ref={lastRef} type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data && props.data.personalData && props.data.personalData[0] ? props.data.personalData[0].lastName : false} /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Collect last name of user?</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input  ref={emailRef} type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data && props.data.personalData && props.data.personalData[0] ? props.data.personalData[0].email : false} /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Collect email address of user</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input  ref={phoneRef} type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data && props.data.personalData && props.data.personalData[0] ? props.data.personalData[0].phone : false}  /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Collect phone number of user</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input  ref={addressRef} type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data && props.data.personalData && props.data.personalData[0] ? props.data.personalData[0].address : false}   /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Collect physical address of user</span></label>
						</div>
					</form>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({}) => ({

});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectUserData);
