// Combine all the reducers
import {combineReducers} from 'redux';
import signIn from './signIn'
import allUsers from './allUsers'
import allGitLabRepos from './allGitlabRepos'
import notConnectedPipelines from './notConnectedPipelines'
import connectedPipelines from './connectedPipelines'
import allPrototypes from './allPrototypes'
import allProjects from './allProjects'
import ssoUserData from './ssoUserData'
import myNotifications from './myNotifications'
import gitlabGroups from './gitlabGroups'
import gitlabGroupId from './gitlabGroupId'
import gitlabSubGroups from './gitlabSubGroups'
import gitlabSubGroupId from './gitlabSubGroupId'
import gitlabRenderRepos from './gitlabRenderRepos'
import projectHasCiCd from './projectHasCiCd'
import wsClient from "./wsclient"
import hasAuthentication from './hasAuthentication'
import creators from './creators'
import filterBy from './filterBy'
import filteredData from "./filteredData";
import repoLoading from "./repoLoading";
import assets from "./allAssets"
import allTests from "./allUserTests"
import preQuestionData from './preQuestions'
import data from './userTest'
export default combineReducers({
    session: signIn,
    users: allUsers,
    repos: allGitLabRepos,
    notConnectedPipelines: notConnectedPipelines,
    connectedPipelines: connectedPipelines,
    prototypes: allPrototypes,
    projects: allProjects,
    ssoUserData,
    notifications: myNotifications,
    gitlabGroups: gitlabGroups,
    gitlabSubGroups: gitlabSubGroups,
    gitlabGroupId: gitlabGroupId,
    gitlabSubGroupId: gitlabSubGroupId,
    gitlabRenderRepos: gitlabRenderRepos,
    wsClient: wsClient,
    hasCiCd: projectHasCiCd,
	hasAuthentication: hasAuthentication,
	creators,
	filterBy,
	filteredData,
	repoLoading,
    assets,
	allTests,
	preQuestionData,
	userTestData: data
});
