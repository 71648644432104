import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import Select from "react-select";
import {preQuestions} from "../../actions/preQuestions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import allTests from "../../util/usertesting/allTests";
import updatePreQuestions from "../../util/usertesting/updatePreQuestions";
import {allTestData} from "../../actions/allUserTests";

const PreQuestionData = props => {
	const answerRef = useRef()
	const [selected, setSelected] = useState()
	const [questionData, setQuestionData] = useState([]);
	const questionRef = useRef()
	let arr = []

	useEffect(()=> {
		props.preQuestions(props.data.preTestData)
	},[])

	useEffect(()=> {
	// question added enable handlers
		let selected = null

		const draggable = document.querySelectorAll('ol li');
		draggable.forEach(node => {
			node.addEventListener('dragstart', dragStart)
			node.addEventListener('dragover', dragOver)
			node.addEventListener('dragEnd', dragEnd)
		})

		function dragOver(e) {
			e.preventDefault()
			if (isBefore(selected, e.currentTarget)) {
				e.currentTarget.parentNode.insertBefore(selected, e.currentTarget)
			} else {
				e.currentTarget.parentNode.insertBefore(selected, e.currentTarget.nextSibling)
			}
			e.dataTransfer.dropEffect = "move";
		}

		function dragEnd() {
			selected = null
		}

		function dragStart(e) {
			e.dataTransfer.effectAllowed = 'move'
			e.dataTransfer.setData('text/plain', null)

			selected = e.target
		}

		function isBefore(el1, el2) {
			let cur
			if (el2 && el1 && el2.parentNode === el1.parentNode) {
				for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
					if (cur === el2) return true
				}
			}
			return false;
		}
	},[props.preQuestionData])


	async function handleRemove (event, index, value) {
		event.preventDefault()

		let newData = [...props.preQuestionData]
		let indexed = newData.indexOf(value)

		newData.splice(indexed ,1)

		props.preQuestions(newData)
	}
	async function handleAdd (question, answer) {
		if (question && answer) {
			let data = [...props.preQuestionData]
			data.push({question: await question, answer: await answer})
			questionRef.current.value = ''
			questionRef.current.focus()
			props.preQuestions(data)
		}
		if (!question && answer) {
			questionRef.current.focus()
		}
		if (question && !answer) {
			answerRef.current.focus()
		}
		if (!question && !answer) {
			answerRef.current.focus()
		}
	}

	function handleSave () {
		let data = props.data
		data.preTestData = props.preQuestionData
		updatePreQuestions(data).then(response => {
			props.handleCloseNewModal()
			allTests().then(data => {
				props.allTestData(data)
			})
		})
	}
	console.log('hi', props)
	return(
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Add Pre / Starting Questions</h6>
					</div>
					<form className={"pd-30"}>
						<div className={"d-flex"}>
							<div className={"mg-b-20 flex"} style={{flex: '2'}}>
								<label>Question</label>
								<input ref={questionRef} className="form-control" placeholder={'Are you a student?'}/>
							</div>
							<div className={"mg-b-20 flex mg-l-8"} style={{flex: '1'}}>
								<label>Answer Type</label>
								<Select
									ref={answerRef}
									options={[
										{label: 'True / False', value: 'bool'},
										{label: 'Yes / No', value: 'yes'},
										{label: '1-10 Scale', value: 'scale'},
										{label: 'Open Answer', value: 'input'},

									]}
									className="basic-multi-select"
									classNamePrefix="select"
									onChange={setSelected}
								/>
							</div>
						</div>

						<div className={"mg-b-20"}>
							<button onClick={()=>{handleAdd(questionRef.current.value, selected ? selected.label : null)}} className="btn ripple btn-secondary" type="button" >Add Question</button>
						</div>
						<div className={"mg-b-20"}>
							<ol>
								{
									props.preQuestionData ? props.preQuestionData.map((question, index) => {
										return (
											<li style={{cursor: 'grab'}} id={`id${index}`} draggable={true} className="mg-8 d-flex justify-content-between" key={index}>
												<div>
													<span>{question.question}</span> -
													<strong className={'primary-text text-primary'}>{question.answer}</strong>
												</div>
												<button onClick={(event)=> {handleRemove(event, index, question)}} id="readMore" type="button"
														className="btn btn-sm btn-secondary mg-0 mg-r-8"><FontAwesomeIcon icon={`fa-solid fa-close`} />
												</button>
											</li>
										)
									}) : <></>
								}

							</ol>
						</div>
					</form>
					<div className="modal-footer">
						<button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
						<button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({preQuestionData}) => ({
	preQuestionData
});

const mapDispatchToProps = dispatch => ({
	preQuestions: (data) => dispatch(preQuestions(data)),
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PreQuestionData);
