import React from 'react';
import logo from '../../assets/images/brand/p-logo-white.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

const Unauthorized = props => {
    let navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('redirect')
    console.log(page)
    return(
        <div className='message-page'>
            <img src={logo}/>
            <h1>Please Login</h1>
            <p>You need to be logged into our system to view this page.</p>
            <p>Please login, or create an account.</p>
            <div>
              <button onClick={()=> { localStorage.setItem('redirect', page); navigate(`../sign-in`)}} type="button" className="btn btn-icon-text my-2 mr-2 btn-secondary mg-r-8">
                    <FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-lock`} />Login
                </button>
            </div>
        </div>
    )
}

export default Unauthorized
