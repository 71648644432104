import React from "react";
import {Navigate, Route} from "react-router-dom";

export const AuthRoute = (path, Component, loggedIn, data) => {

	if (data) {
		console.log('line 7 data: ', data)
		let url = `/apps/${data.repoName}`

		if (data.collectPersonal) {
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/information" /> : <Component />}
				/>
			);
		} else if (data.preTest) {
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/questions"/> : <Component/>}

				/>
			);
		} else if (data.introductionText){
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/welcome" /> : <Component />}
				/>
			);
		} else if (data.introduction){
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/video" /> : <Component />}
				/>
			);
		} else if (data.recordTest){
			return (
				<Route
					exact
					path={path}
					element={loggedIn ? <Navigate to="/confirmation" /> : <Component />}
				/>
			);
		}	else {
			return (
				<Route
					path={path}
					element={window.location.href = url}
				/>
			);
		}
	}else {
		return (
			<Route
				exact
				path={path}
				element={loggedIn ? <Navigate to="/sign-out" /> : <Component />}
			/>
			)

	}
};
