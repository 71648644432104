import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import configureStore from "./store/store";
import {checkLoggedIn} from "./util/session/checkLoggedIn";
import reportWebVitals from "./util/reportWebVitals";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import "./assets/css/reset.css";
import "./assets/css/style.css";
// msal Imports
import {msalConfig} from "./util/sso/authConfig";
import {PublicClientApplication} from "@azure/msal-browser";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
 export const pca = new PublicClientApplication(msalConfig);

 // Account selection logic is app dependent. Adjust as needed for different use cases.
 const accounts = pca.getAllAccounts();

 if (accounts.length > 0) {
   pca.setActiveAccount(accounts[0]);
 }

let preloadedState = {};

const renderApp = (preloadedState) => {
    const store = configureStore(preloadedState);
  ReactDOM.render(
    <Provider
      store={store}
    >
      <BrowserRouter forceRefresh={true}>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
};

// before rendering app check to see if user has session is established and logged in.
(async () => renderApp(await checkLoggedIn()))();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
