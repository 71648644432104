import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Wrapper from './global/Wrapper';
import startTest from "../util/usertesting/startTest";

const Welcome = ({ session, users }) => {
	const navigate = useNavigate();

	console.log(session.data);

	const handleClick = () => {
		startTest({ session, users });
	}

	async function handleBackClick() {
		if (session.data.collectPersonal) {
			navigate("/information")
		} else {
			navigate("/welcome")
		}

	}
	return(
		<Wrapper>
			<div
				style={{ maxWidth: '768px', margin: '0 auto' }}
				dangerouslySetInnerHTML={{ __html: session.data.introductionData.text }}
			/>
			<div style={{display: 'flex'}}>
			{
				session.data.collectPersonal ? <button onClick={handleBackClick} className="btn ripple btn-primary mg-r-8"><span>Back</span></button> : <></>
			}
			{
				session.data.introduction
					? <button onClick={() => navigate('/video')} className="btn ripple btn-primary"><span>Next</span></button>
					: <button onClick={handleClick} className="btn ripple btn-primary"><span>Next</span></button>
			}
			</div>
		</Wrapper>
	)
}

const mapStateToProps = ({ session, users }) => ({
	session,
	users
});

export default connect(
	mapStateToProps
)(Welcome);
